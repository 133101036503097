import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfigString = process.env.REACT_APP_FIREBASE_CONFIG;

if (!firebaseConfigString) {
    throw new Error('REACT_APP_FIREBASE_CONFIG is not defined');
}
const firebaseConfig = JSON.parse(firebaseConfigString || '{}');
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default analytics;
